/*
 * @Descripttion: 数据中心
 * @version: 2.2.1
 * @LastEditors: 邓亚鑫
 * @LastEditTime: 2022-03-23 13:40
 * @version: 2.2.2
 * @LastEditors: 刘志坤
 * @LastEditTime: 2022-04-6 11:40
 */
<template>
  <section class="data-center" ref="parent">
    <a-row :gutter="20">
      <a-col :span="12">
        <a-card class="charts-card">
          <section class="charts-head">
            <span class="charts-name">印章分布</span>
            <a-tooltip placement="right" color="#FFFFFF" :getPopupContainer="node => node">
              <template #title>
                <span style="color: #333333"
                  >印章分布地图：在当前用户的数据权限下，展示各个省（直辖市，自治区）的印章数量，并形成直观的对比</span
                >
              </template>
              <QuestionCircleOutlined class="charts-icon" />
            </a-tooltip>
          </section>
          <mapEcharts
            :mapData="leftMapData"
            :visualMap="leftVisualMap"
            v-if="JSON.stringify(leftMapData) != '[]'"
            :type="true"
          />
        </a-card>
      </a-col>
      <a-col :span="12">
        <a-card class="charts-card">
          <section class="charts-head">
            <span class="charts-name">用印地点分布</span>
            <a-tooltip placement="right" color="#FFFFFF" :getPopupContainer="node => node" class="tooltip">
              <template #title>
                <span style="color: #333333"
                  >用印地点分布：在当前用户的数据权限下，展示各省（直辖市，自治区）用印总次数分布</span
                >
              </template>
              <QuestionCircleOutlined class="charts-icon" />
            </a-tooltip>
          </section>
          <!-- :legend="rightLegend" -->
          <mapRightEcharts
            :mapData="rightMapData"
            :visualMap="rightVisualMap"
            :showProvince="rightShowProvince"
            v-if="JSON.stringify(rightMapData) != '[]'"
            :type="false"
          />
        </a-card>
      </a-col>
    </a-row>
    <a-row style="margin-top: 24px">
      <a-col :span="24">
        <a-card style="padding-top: 0;height:555px">
          <a-row
            style="border-bottom: 1px solid #d7dbe0; padding-bottom: 14px; margin-bottom: 20px"
            justify="space-between"
          >
            <a-col>
              <section>
                <span class="charts-name">印章用印分析</span>
                <a-tooltip placement="right" color="#FFFFFF" :getPopupContainer="node => node" class="line-tooltip">
                  <template #title>
                    <span style="color: #333333">印章用印分析：展示月度或者季度的印章使用情况</span>
                  </template>
                  <QuestionCircleOutlined class="charts-icon" />
                </a-tooltip>
              </section>
            </a-col>
            <a-col>
              <a-radio-group v-model:value="timeType" button-style="solid">
                <a-radio-button :value="1">月度</a-radio-button>
                <a-radio-button :value="2">季度</a-radio-button>
              </a-radio-group>
              <a-range-picker
                dropdownClassName="data-center-range"
                format="YYYY-MM-DD"
                separator="→"
                @change="dataTimeChange"
                @openChange="openChange"
                v-model:value="dateRange"
                :disabledDate="disabledDate"
                @calendarChange="onCalendarChange"
                :class="checkedPick?'checked-pick':''"
                ><template #suffixIcon>
                  <CalendarOutlined />
                </template>
              </a-range-picker>
            </a-col>
          </a-row>
          <lineEcharts :linedata="linedata" v-if="JSON.stringify(linedata) != '{}'" />
        </a-card>
      </a-col>
    </a-row>
  </section>
</template>

<script>
import { defineComponent, onMounted, toRefs, reactive, watch, ref } from 'vue'
import { sealStatistic, sealAnalyse, sealViceStatistic } from '@/apis/center'
import mapEcharts from './components/mapEcharts'
import mapRightEcharts from './components/mapRightEcharts'
import lineEcharts from './components/lineEcharts'
import { QuestionCircleOutlined, CalendarOutlined } from '@ant-design/icons-vue'
import defaultMapData from './components/index'
import cityCode from './components/map'
import moment from 'moment'
export default defineComponent({
  name: 'dataCenter',
  components: {
    mapEcharts,
    lineEcharts,
    QuestionCircleOutlined,
    CalendarOutlined,
    mapRightEcharts
  },
  setup() {
    const state = reactive({
      leftMapData: [],
      rightMapData: [],
      leftVisualMap: {
        show: true,
        min: 1,
        max: 200,
        //   text: ["High", "Low"],
        realtime: true,
        // calculable: true,
        // 左侧图标颜色配置
        color: ['#0050B3', '#0D73DD', '#43A5FF', '#95CEFF', '#F2F3F5'],
        itemWidth: 14,
        itemHeight: 154
        // hoverLink: false
      },
      rightShowProvince: true,
      rightVisualMap: {
        itemWidth: 8, //图形的宽度，即长条的宽度。
        itemHeight: 8,
        // hoverLink: false,
        // 右侧图颜色配置
        pieces: [
          {
            gt: 5000,
            label: '>5000',
            color: '#5475F5',
            symbol: 'circle'
          },
          {
            gte: 3000,
            lte: 5000,
            label: '3000~5000',
            color: '#9FEAA5',
            symbol: 'circle'
          },
          {
            gte: 1000,
            lte: 3000,
            label: '1000~3000',
            color: '#85DAEF',
            symbol: 'circle'
          },
          {
            gte: 500,
            lte: 1000,
            label: '500~1000',
            color: '#74E2CA',
            symbol: 'circle'
          },
          {
            gte: 300,
            lte: 500,
            label: '300~500',
            color: '#FFB40D',
            symbol: 'circle'
          },
          {
            gte: 1,
            lte: 300,
            label: '1~300',
            color: '#F3FAFF',
            symbol: 'circle'
          }
        ],
        textStyle: {
          fontSize: 12
        }
      },
      linedata: {},
      lineParams: {
        startTime: '',
        endTime: ''
      },
      timeType: 1, //1-月度，2-季度
      currentdate: '',
      dateRange: [],
      mapCenter: [],
      mapRightCenter: []
    })
    const checkedPick = ref(false);
    const dates = ref()
    const mapData = async (mapApi, type) => {
      let mapList = [],
        mapObj = {},
        codeArr = []
      const res = await mapApi()

      if (res.success) {
        //后端只
        res.data &&
          res.data.forEach(item => {
            if (item.province) {
              mapObj[item.province] = item.count
              codeArr.push(item.province)
            }
          })
        cityCode.forEach(val => {
          let temp = {
            name: val.name,
            code: val.code,
            showName: val.showName
          }
          if (codeArr.includes(val.code)) {
            temp.value = mapObj[val.code] || 0
          } else {
            temp.value = 0
          }
          mapList.push(temp)
        })
        if (type == 1) state.leftMapData = mapList
        if (type == 2) state.rightMapData = mapList
      }
      console.log(state.leftMapData,'djiaojdi');
      return res
    }
    const quertLineData = async () => {
      const date = [],
        applySeal = [],
        actualSeal = [],
        applyProcess = []
      const res = await sealAnalyse(state.lineParams)
      if (res.success) {
        for (const key in res.data) {
          date.push(key.replace(/-/g, '/'))
          applySeal.push(res.data[key][0])
          actualSeal.push(res.data[key][1])
          applyProcess.push(res.data[key][2])
        }
      }
      state.linedata = { date, applySeal, actualSeal, applyProcess }
    }
    const dataTimeChange = (value, string) => {
      if (string[0] && string[1]) {
        state.timeType = ''
        state.dateRange = string
      }else{
        state.timeType = 1
      }
    }
    const openChange = (status) => {
      checkedPick.value = status;
      console.log(checkedPick);
    }
    const getCurrentDate = val => {
      let date = new Date()
      let month = date.getMonth() + 1
      if (month > 0 && month < 10) {
        month = '0' + month
      }
      const nowDay = date.getDate() > 9 ? date.getDate() : '0' + date.getDate()
      state.lineParams.endTime = date.getFullYear() + '-' + month + '-' + nowDay
      if (!val) {
        state.lineParams.startTime = date.getFullYear() + '-' + month + '-01'
      } else {
        let start = quarter(month)
        state.lineParams.startTime = date.getFullYear() + '-' + start + '-01'
      }
      quertLineData()
    }
    // 日期选择器限制(当前日期及选中日期三十天内)
    const disabledDate = (current) => {
        if(!dates.value) {
          return current && current > Date.now()
        } else {
          return (
          (current && current > Date.now()) ||
          current < moment(dates.value[0]).subtract(3, "month")
          );
        }
    }
    const onCalendarChange = val => {
      dates.value = val
    }
    // 判断季度
    const quarter = month => {
      let result = parseInt((month - 1) / 3) * 3 + 1
      if (result < 10) {
        result = '0' + result
      }
      return result
      // let result
      // if (month >= 1 && month <= 3) result = 1
      // if (month >= 4 && month <= 6) result = 4
      // if (month >= 7 && month <= 9) result = 7
      // if (month >= 10 && month <= 12) result = 10
    }
    onMounted(async () => {
      const { code } = await mapData(sealStatistic, 1)
      if (+code !== 401 && +code !== 403 && +code !== 406) {
        mapData(sealViceStatistic, 2)
        getCurrentDate()
      }
    })
    watch(
      () => state.timeType,
      newValue => {
        if (newValue) {
          state.dateRange = []
        }
        if (state.timeType == 1) getCurrentDate()
        if (state.timeType == 2) getCurrentDate(1)
      }
    )
    watch(
      () => state.dateRange,
      newValue => {
        state.lineParams.startTime = newValue[0]
        state.lineParams.endTime = newValue[1]
        if (state.lineParams.startTime && state.lineParams.endTime) {
          quertLineData()
        }
      }
    )
    return {
      ...toRefs(state),
      quertLineData,
      dataTimeChange,
      openChange,
      dates,
      disabledDate,
      onCalendarChange,
      checkedPick
    }
  }
})
</script>

<style lang="scss" scoped>
.data-center {
  margin: 0 24px;
  overflow:hidden;
  .charts-card {
    padding-top: 0;
    position: relative;
    height: 362px;
    .charts-head {
      position: absolute;
      left: 24px;
      top: 30px;
      z-index: 10;
    }
  }
  .charts-name {
    font-size: 16px;
    color: #111111;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    margin-right: 8px;
  }
  .charts-icon {
    font-size: 16px;
    color: #333333;
    cursor: pointer;
  }
  :deep(.ant-tooltip-content) {
    width: 500px;
  }
  .line-tooltip {
    :deep(.ant-tooltip-content) {
      width: 330px;
    }
  }
  :deep(.ant-radio-button-wrapper) {
    padding: 0 25px;
  }
  :deep(.ant-calendar-picker-input.ant-input) {
    padding-left: 0;
  }
}
@media screen and (max-width: 1440px) {
  .data-center {
    :deep(.ant-tooltip-content) {
      width: 360px;
    }
    .tooltip {
      :deep(.ant-tooltip-content) {
        width: 320px;
      }
    }
  }
}
@media screen and (min-width: 1440px) and (max-width: 1660px) {
  .data-center {
    :deep(.ant-tooltip-content) {
      width: 400px;
    }
    .tooltip {
      :deep(.ant-tooltip-content) {
        width: 380px;
      }
    }
  }
}
.checked-pick{
  :deep(.ant-calendar-picker-input){
    border: 1px solid #C3161C !important;
  }
}


</style>