/*
 * @Descripttion: 线性图表
 * @version: 2.2.1
 * @LastEditors: 邓亚鑫
 * @LastEditTime: 2022-03-24 13:40
 */
<template>
  <div id="lineEcharts" style="height: 440px" ></div>
</template>
<script>
import echarts from 'echarts'
import { defineComponent, onMounted, onUpdated } from 'vue'
export default defineComponent({
  name:"lineEcharts",
  components: {},
  props: {
    linedata: {
      type: Object,
      default: {},
    },
  },
  setup(props) {
    const initchart = () => {
      const colorList = ['#6699ff', '#52cca3', '#9580ff', '#bfb3ff', '#bdc8de']
      var option = {
        backgroundColor: '#fff',
        legend: {
          top: '5%',
          left: '1%',
          // itemWidth: 6,
          itemGap: 20,
          textStyle: {
            color: '#556677',
          },
        },
        tooltip: {
          trigger: 'axis',
          transitionDuration:0,
          backgroundColor: 'rgba(255,255,255,0)',
          // position: function (point, params, dom, rect, size) {
          //   return [point[0], point[1] + 8]
          // },
          confine:true,
          axisPointer: {
            label: {
              show: false,
              // backgroundColor: '#000',
              color: '#556677',
              //   borderColor: 'rgba(0,0,0,0)',
              //   shadowColor: 'rgba(0,0,0,0)',
              shadowOffsetY: 0,
            },
            lineStyle: {
              width: 1,
              color: '#a4b6e9',
              type: 'dashed',
            },
          },
          textStyle: {
            color: '#5c6c7c',
          },
          //   borderColor: '#e8eafd',
          //   borderWidth: 2,
          padding: [0, 0],
          formatter: (params) => {
            let html = `<div style='background: #ffffff;border-radius:10px;border: 1px solid #e8eafd;box-shadow: 0px 8px 8px 0px rgba(112,143,216,0.21);padding:12px 20px'><div  style="font-size: 16px;font-weight: 600;color: #35415b;margin-bottom:10px">${
              params[0].name
            }</div>`
            params.forEach((v) => {
              html += `<div>${v.marker}<div style="padding-left:3px;display: inline-block;min-width:120px;margin-bottom:8px"><span style="color:#999;font-size:14px;font-weight:400;">${v.seriesName}</span><span style="color:#333;font-size:14px;font-weight:500;padding-left:12px;text-align:right;float:right">${v.value}</span></div></div>`
            })
            html += '</div></div>'
            return html
          },
          // extraCssText: 'box-shadow: 1px 0 2px 0 rgba(163,163,163,0.5)',
        },
        // 位置
        grid: {
          top: '15%',
          left: '5%',
          right: '3%',
        },
        xAxis: [
          {
            type: 'category',
            data: props.linedata.date,
            axisLine: {
              lineStyle: {
                color: '#ededed',
              },
            },
            axisTick: {
              show: true,
            },
            axisLabel: {
              // interval: 0,
              textStyle: {
                color: '#999',
              },
              // 默认x轴字体大小
              fontSize: 12,
              // margin:文字到x轴的距离
              margin: 15,
            },
            axisPointer: {
              label: {
                // padding: [11, 5, 7],
                padding: [0, 0, 10, 0],
                // 这里的margin和axisLabel的margin要一致!
                margin: 15,
                // 移入时的字体大小
                fontSize: 12,
              },
            },
          },
        ],
        yAxis: [
          {
            minInterval: 1,
            type: 'value',
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            axisLabel: {
              textStyle: {
                color: '#999999',
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: '#f2f2f2',
                type: 'dashed',
              },
            },
          },
        ],
        series: [
          {
            name: '申请盖印次数',
            type: 'line',
            data: props.linedata.applySeal,
            symbolSize: 4,
            symbol: 'circle',
            smooth: true,
            label:'show',
            showSymbol: props.linedata.applySeal.length==1?true:false,
            lineStyle: {
              width: 2,
              color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                {
                  offset: 0,
                  color: 'rgba(102,152,254,0)',
                },
                {
                  offset: 0.1,
                  color: 'rgba(102,152,254,.3)',
                },
                {
                  offset: 0.2,
                  color: 'rgba(102,152,254,.4)',
                },
                {
                  offset: 0.3,
                  color: '#6698FE',
                },
                {
                  offset: 1,
                  color: '#6698FE',
                },
              ]),
              shadowColor: 'rgba(102,152,254,.5)',
              shadowBlur: 10,
              shadowOffsetY: 5,
            },
            itemStyle: {
              normal: {
                color: colorList[0],
                borderColor: colorList[0],
              },
            },
          },
          {
            name: '实际盖印次数',
            type: 'line',
            data: props.linedata.actualSeal,
            symbolSize: 4,
            symbol: 'circle',
            smooth: true,
            label:'show',
            showSymbol: props.linedata.actualSeal.length==1?true:false,
            lineStyle: {
              width: 2,
              color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                {
                  offset: 0,
                  color: 'rgba(82,204,163,0)',
                },
                {
                  offset: 0.1,
                  color: 'rgba(82,204,163,.3)',
                },
                {
                  offset: 0.2,
                  color: 'rgba(82,204,163,.4)',
                },
                {
                  offset: 0.4,
                  color: '#52cca3',
                },
                {
                  offset: 1,
                  color: '#52cca3',
                },
              ]),
              shadowColor: 'rgba(82,204,163,.5)',
              shadowBlur: 10,
              shadowOffsetY: 5,
            },
            itemStyle: {
              normal: {
                color: colorList[1],
                borderColor: colorList[1],
              },
            },
          },
          {
            name: '申请文件数',
            type: 'line',
            data: props.linedata.applyProcess,
            symbolSize: 4,
            symbol: 'circle',
            smooth: true,
            label:'show',
            showSymbol: props.linedata.applyProcess.length==1?true:false,
            lineStyle: {
              width: 2,
              color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                {
                  offset: 0,
                  color: 'rgba(149,128,255,0)',
                },
                {
                  offset: 0.1,
                  color: 'rgba(149,128,255,.3)',
                },
                {
                  offset: 0.2,
                  color: 'rgba(149,128,255,.4)',
                },
                {
                  offset: 0.4,
                  color: '#9580ff',
                },
                {
                  offset: 1,
                  color: '#9580ff',
                },
              ]),
              shadowColor: 'rgba(149,128,255,.5)',
              shadowBlur: 10,
              shadowOffsetY: 5,
            },
            itemStyle: {
              normal: {
                color: colorList[2],
                borderColor: colorList[2],
              },
            },
          },
        ],
      }
      var mychart = echarts.init(document.getElementById('lineEcharts'))
      mychart.setOption(option)
      window.addEventListener('resize', () => {
        // 随屏幕宽度大小改变echarts
        mychart.resize()
      })
    }
    onMounted(() => {
      initchart()
    })
    onUpdated(()=>{
      initchart()
    })
    return {}
  },
})
</script>
<style lang='scss' scoped>
</style>