import request from '@/utils/request'
const api = {
    pc: "/pc/data/statistics"
}
// 印章分析
export function sealStatistic(params) {
    return request({
        url: `${api.pc}/seal/statistic`,
        method: 'GET',
        params
    })
}
// 印章用印分析
export function sealAnalyse(params) {
    return request({
        url: `${api.pc}/seal/analyse`,
        method: 'GET',
        params
    })
}
// 盖印数量
export function sealViceStatistic(params) {
    return request({
        url: `${api.pc}/sealVice/statistic`,
        method: 'GET',
        params
    })
}