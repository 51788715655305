const defaultMapData = [
  {
    // name: "北京",
    // value: 599,
    province: 110000,
    count: 599
  },
  {
    // name: "上海",
    // value: 142,
    province: 310000,
    count: 142
  },
  {
    // name: "黑龙江",
    // value: 44,
    province: 230000,
    count: 44
  },
  {
    // name: "深圳",
    // value: 92,
    province: 440000,
    count: 92
  },
  {
    // name: "湖北",
    // value: 810,
    province: 420000,
    count: 810
  },
  {
    // name: "内蒙古",
    // value: 810,
    province: 150000,
    count: 810
  },
  {
    // name: "四川",
    // value: 453,
    province: 510000,
    count: 453
  },
]
export default defaultMapData